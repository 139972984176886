import axios from 'axios'
export class FormAjax extends HTMLFormElement {
  constructor() {
    super()
    this.alerts = []
    this.addEventListener('submit', this.handleSubmit.bind(this))
  }

  async handleSubmit(evt) {
    evt.preventDefault()
    const formData = new FormData(this)
    this.cleanAlerts()

    try {
      const response = await axios.post(this.getAttribute('action') , formData, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
      })
      if (response.data.message) {
        this.insertAlert(response.data.message, 'alert -success')
      }
      this.reset()
    } catch (error) {
      if (typeof error.response !== 'undefined') {
        const errors =  error.response.data.errors ?? null
        for (error in errors) {
          if (errors.hasOwnProperty(error)) {
            this.insertAlert(errors[error], 'alert -fail')
          }
        }
      }
    }
  }

  insertAlert(content, className) {
    const element = document.createElement('div')
    element.className = className
    element.innerHTML = content
    this.prepend(element)
    this.alerts.push(element)
  }

  cleanAlerts() {
    if (this.alerts.length) {
      for (let i = 0; i < this.alerts.length; i++) {
        this.alerts[i].remove()
      }
    }
    this.alerts = []
  }
}
